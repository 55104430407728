import React from 'react'
import { StaticQuery, graphql, PageProps } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { parseAddress } from '@/tools/meta'
import { AboutMetaQuery } from '../../../types/graphql-types'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import H3 from '@/components/H3'
import GoogleMap from '@/components/GoogleMap'
import TelephoneLink from '@/components/TelephoneLink'

const title = 'アクセス・会社情報'

const query = graphql`
  query AboutMeta {
    site {
      siteMetadata {
        address {
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        telephone
        faxNumber
        staffs
      }
    }
  }
`

const About = (props: PageProps) => (
  <StaticQuery
    query={query}
    render={(data: AboutMetaQuery) => {
      const meta = data?.site?.siteMetadata
      const staffs = meta?.staffs || []
      const borderClass = 'flex mb-4 pb-4 border-b-2 border-purple-300'

      return (
        <Layout title={title}>
          <SEO title={title} pathname={props.location.pathname} />

          <section id="map" className="map mb-10">
            <H3 text="アクセス" />
            <GoogleMap height={400} />
          </section>

          <section id="company" className="company">
            <H3 text="会社概要" wrapStyle={{ paddingTop: isMobile ? 0 : 40 }} />

            <div className="my-10 text-md md:text-xl">
              <div className={borderClass}>
                <div className="w-1/3 md:w-1/4 font-bold">所在地</div>
                <div className="w-2/3 md:w-3/4">
                  <p className="mb-2">
                    〒{meta?.address?.postalCode} {parseAddress(meta?.address)}
                  </p>
                  <p>
                    <TelephoneLink />
                  </p>
                  <p>FAX. {meta?.faxNumber}</p>
                </div>
              </div>
              <div className={borderClass}>
                <div className="w-1/3 md:w-1/4 font-bold">スタッフ</div>
                <div className="w-2/3 md:w-3/4">
                  {staffs.map((staff, i) => (
                    <p className="mb-1" key={`${staff}-${i}`}>
                      {staff}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )
    }}
  />
)

export default About
