import React from 'react'

interface Props {
  className?: string
  height?: number
}

const GoogleMap: React.FC<Props> = ({ className, height }) => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13125.3332928411!2d134.9558102!3d34.671536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43ed2b55227e34c3!2z5YWQ56ul55m66YGU5pSv5o-044O75pS-6Kqy5b6M562J44OH44Kk44K144O844OT44K5IOOBleOBj-OBo-OBkw!5e0!3m2!1sja!2sjp!4v1589232375440!5m2!1sja!2sjp"
    style={{ height }}
    tabIndex={0}
    className={className}
  ></iframe>
)

GoogleMap.defaultProps = {
  className: 'w-full',
  height: 300,
}

export default GoogleMap
